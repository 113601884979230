<template>
  <div>
    <b-container>
      <b-card>
        <b-row>
          <b-col sm="5">
            <i2-form-input v-model="menu.name" label="Nome" name="name" rule="required" />
          </b-col>
          <b-col sm="2">
            <b-form-group
              label="Mostrar texto"
            >
              <b-form-checkbox
                v-model="menu.show_label"
                name="check-button"
                switch
              />
            </b-form-group>
          </b-col>
          <b-col sm="2">
            <b-form-group
              label="ativo"
            >
              <b-form-checkbox
                v-model="menu.active"
                name="check-button"
                switch
              />
            </b-form-group>
          </b-col>
          <b-col sm="3" align-self="center" class="text-right">
            <b-button variant="primary" @click="save">
              <feather-icon icon="SaveIcon" class="mr-1" />
              Salvar
            </b-button>
          </b-col>
        </b-row>
      </b-card>
      <b-card>
        <b-row class="mb-1">
          <b-col sm="12" class="text-right">
            <b-button @click="newItem">
              Novo Item
            </b-button>
          </b-col>
        </b-row>
        <ul class="list-group list-group-collapse">
          <draggable
            :list="items"
            :group="{name:`items`, pull:'clone', put:false }"
            handle=".handle"
          >
            <li v-for="item of items" v-if="!item.isDeleted" :key="item.uuid" class="list-group-item">
              <div>
                <i style="font-size:10px" class="fa fa-align-justify handle cursor-move" />
                <span class="ml-1" :class="{ 'selected': item.isSelected }" @click="selectItem(item)">
                  {{ item.name }}
                  <span v-if="item.pageUuid !== ''">({{ item.pageName }})</span>
                </span>
                <span class="float-right">
                  <feather-icon v-if="item.isOpen" icon="ChevronDownIcon" size="20" @click="displayItem(item)" />
                  <feather-icon v-if="!item.isOpen" icon="ChevronRightIcon" size="20" @click="displayItem(item)" />
                  <feather-icon icon="TrashIcon" size="20" @click="removeItem(item)" />
                  <feather-icon icon="EditIcon" size="20" @click="editItem(item)" />
                </span>
                <ul v-if="item.child.length > 0 && item.isOpen" class="list-group">
                  <draggable
                    :list="item.child"
                    :group="{name:`items_${item.uuid}`, pull:'clone', put:false }"
                    class="cursor-move"
                  >
                    <li v-for="submenu of item.child" v-if="!submenu.isDeleted" :key="submenu.uuid"
                        class="list-group-item"
                    >
                      {{ submenu.name }}
                      <span v-if="submenu.pageUuid != ''">({{ submenu.pageName }})</span>
                      <span class="float-right cursor-default">
                        <feather-icon icon="TrashIcon" size="20" @click="removeItem(submenu, item)" />
                        <feather-icon icon="EditIcon" size="20" @click="editItem(submenu)" />
                      </span>
                    </li>
                  </draggable>
                </ul>
              </div>
            </li>
          </draggable>
        </ul>
      </b-card>
    </b-container>
    <b-modal
      id="form-sidebar"
      ref="sideBar"
      size="lg"
      :visible="showNewItemModal"
      backdrop
      :no-close-on-esc="true"
      :no-close-on-backdrop="true"
      bg-variant="white"
      title="Novo Item"
      right
      shadow
      scrollable
      ok-title="Salvar"
      cancel-title="Cancelar"
      sidebar-class="sidebar-lg"
      header-class="content-sidebar-header"
      footer-class="content-sidebar-footer"
      @ok="onSubmit"
      @cancel="onCancel"
    >
      <template #header="{hide}">
        <div class="d-flex justify-content-between align-items-center w-100">
          <feather-icon
            class="ml-1 cursor-pointer float-right"
            icon="XIcon"
            size="16"
            @click="hide"
          />
        </div>
      </template>
      <validation-observer ref="formValidation">
        <b-form>
          <b-row>
            <b-col>
              <i2-form-input v-model="model.name"
                             name="name"
                             rules="required"
                             label="Nome"
              />
            </b-col>
          </b-row>

          <b-row>
            <b-col>
              <label>Icone</label><br>
              <b-card class="icon-card cursor-pointer text-center mb-2 mx-50" @click="showIcons = true">
                <feather-icon :icon="model.icon" size="24" />
              </b-card>
            </b-col>
          </b-row>
          <b-row>
            <b-col>
              <i2-form-vue-select v-model="model.page"
                                  name="name"
                                  :options="pages"
                                  label="Página"
              />
            </b-col>
          </b-row>
        </b-form>
      </validation-observer>
    </b-modal>
    <s-icon-select v-model="showIcons" @onCancel="showIcons = false" @onSelectIcon="setIcon" />
  </div>
</template>

<script>
import draggable from 'vuedraggable'
import SIconSelect from '@/components/i2/content/SIconSelect.vue'
import { v4 as uuidv4 } from 'uuid'

export default {
  name: 'MenuBuilder',
  components: { draggable, SIconSelect },
  data() {
    return {
      showNewItemModal: false,
      showIcons: false,
      pages: [],
      menu: {
        name: '',
        show_label: true,
        uuid: '',
        active: true,
      },
      model: {
        name: '',
        icon: 'HomeIcon',
        type: 'route',
        page_uuid: '',
        isOpen: false,
        page: null,
        isSelected: false,
        isDeleted: false,
        child: [],
        uuid: '',
      },
      currentActive: null,
      items: [],
    }
  },
  mounted() {
    this.getPages()
    const { uuid } = this.$route.params
    if (uuid) {
      this.getData(uuid)
    }
  },
  methods: {

    async getData(uuid) {
      const response = await this.$http.get(`/menus/${uuid}?include=items`)
      this.menu = {
        name: response.name,
        show_label: response.show_title,
        active: response.active,
        uuid: response.uuid,
      }
      this.items = response.items.map(item => {
        item.isSelected = false
        item.isOpen = false
        item.isDeleted = false
        item.pageUuid = item.page?.uuid || ''
        item.pageName = item.page?.name || ''
        item.child = item.child.map(child => {
          child.isDeleted = false
          child.pageUuid = child.page?.uuid || ''
          child.pageName = child.page?.name || ''
          delete (child.page)
          return child
        })
        return item
      })
    },

    newItem() {
      this.showNewItemModal = true
    },

    async onSubmit(bvModalEvt) {
      bvModalEvt.preventDefault()
      const success = await this.$refs.formValidation.validate()
      if (!success) {
        return
      }
      if (this.model.uuid !== '') {
        this.showNewItemModal = false
        this.model.pageUuid = this.model.page?.value || ''
        this.model.pageName = this.model.page?.text || ''
        this.model = {
          name: '',
          icon: 'HomeIcon',
          url: '',
          isOpen: false,
          isSelected: false,
          isDeleted: false,
          pageUuid: '',
          uuid: '',
          page: null,
          child: [],
        }
        return
      }
      const newItem = { ...this.model }
      newItem.uuid = uuidv4()
      newItem.pageUuid = this.model.page?.value || ''
      newItem.pageName = this.model.page?.text || ''
      const currentItem = this.getCurrentItem()
      if (currentItem) {
        currentItem.child.push(newItem)
        if (!currentItem.isOpen) {
          this.displayItem(currentItem)
        }
      } else {
        this.items.push(newItem)
      }

      this.showNewItemModal = false
      this.model = {
        name: '',
        icon: 'HomeIcon',
        url: '',
        isOpen: false,
        isSelected: false,
        isDeleted: false,
        pageUuid: '',
        uuid: '',
        page: null,
        child: [],
      }
    },

    onCancel() {
      this.showNewItemModal = false
    },
    setIcon(icon) {
      this.model.icon = icon
      this.showIcons = false
    },
    displayItem(item) {
      item.isOpen = !item.isOpen
    },
    selectItem(item) {
      item.isSelected = !item.isSelected
      const currentItem = this.items.find(c => c.uuid === this.currentActive)
      let currentUuid = null
      if (currentItem) {
        currentItem.isSelected = false
        currentUuid = currentItem.uuid
      }
      if (currentUuid === item.uuid) {
        this.currentActive = null
        return
      }
      this.currentActive = item.uuid
    },

    getCurrentItem() {
      return this.items.find(c => c.uuid === this.currentActive)
    },

    removeItem(item, parent) {
      if (parent) {
        parent.child = parent.child.filter(itemCollection => itemCollection.uuid !== item.uuid)
        return
      }
      this.items = this.items.filter(itemCollection => itemCollection.uuid !== item.uuid)
    },

    async save() {
      const data = {
        name: this.menu.name,
        uuid: this.menu.uuid,
        active: this.menu.active,
        show_title: this.menu.show_label,
        items: [],
      }
      data.items = this.items.map(item => this.buildRouteItem(item))
      let response = {}
      if (this.menu.uuid === '') {
        response = await this.$http.post('/menus', data)
      } else {
        response = await this.$http.put(`/menus/${this.menu.uuid}`, data)
      }
      if (response.error) {
        this.$notify.error(response.error_message)
        return false
      }
      this.menu.uuid = response.uuid
      this.$notify.success('Menu salvo com sucesso.')
      return true
    },

    editItem(item) {
      this.model = item
      this.model.page = this.pages.find(page => page.value === item.pageUuid)
      this.$forceUpdate()
      this.newItem()
    },

    buildRouteItem(item) {
      if (item.isDeleted) {
        return false
      }
      const menuItem = {
        name: item.name,
        uuid: item.uuid,
        icon: item.icon,
        page_uuid: item.pageUuid,
      }

      if (item.child.length > 0) {
        menuItem.child = []
        for (const child of item.child) {
          const itemParsed = this.buildRouteItem(child)
          if (!itemParsed) {
            continue
          }
          menuItem.child.push(itemParsed)
        }
      }
      return menuItem
    },

    async getPages() {
      const response = await this.$http.get('pages')
      this.pages = response.map(item => (
        {
          value: item.uuid,
          text: item.name,
        }
      ))
    },
  },
}
</script>

<style scoped>
.list-down-btn {
  float: right;
  color: black;
  margin-top: 2px;
}

.list-group-collapse {
  overflow: hidden;
}

.list-group-collapse li ul {
  margin-left: -15px;
  margin-right: -15px;
  margin-bottom: -11px;
  border-radius: 0px;
}

.list-group-collapse li ul {
  border-radius: 0px !important;
  margin-top: 8px;

}

.list-group-collapse li ul li {
  border-radius: 0px !important;
  border-left: none;
  border-right: none;
  padding-left: 32px;
  list-style: none;
}

.list-group .list-group-item:hover {
  background-color: transparent !important;
}

.selected {
  color: green;
}
</style>
